
<template>
  <div class="product-questions">
    <div class="row">
      <div class="col-12">
        <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
          <h5 class="my-0">
            <i class="mdi mdi-playlist-edit mr-1"></i> Frequently Asked Questions
          </h5>
        </div>
        <div role="tablist" class="">
            <b-card no-body class="mb-1 shadow-none" v-for="(question, index) in questions" :key="index">
                <b-card-header header-tag="header" class="bg-transparent" role="tab">
                    <div class="d-sm-flex">
                        <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                            <a v-b-toggle="`questionAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                                <i class="mdi mdi-check-circle mr-1 text-primary"></i>{{question.question}}
                            </a>
                        </h5>
                        <span class="float-right">
                            <b-dropdown variant="light" size="sm" right>
                                <template slot="button-content">
                                    Action
                                </template>
                                <b-dropdown-item-button @click="initEdit(question)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                                <b-dropdown-item-button @click="deleteItem(question.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                        </span>
                    </div>
                </b-card-header>
                <b-collapse :id="`questionAccordion-${index}`" accordion="accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>{{question.answer}}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
      </div>
      <div class="col-12 mt-3 text-right">
        <b-button variant="primary" size="sm" @click.prevent="popupModalShow = true" type="button">
          Add Question</b-button>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update question':'Add question'"
      title-class="font-18" @hide="initializeAll()">
      <product-question-form v-if="popupModalShow" @closeMe="initializeAll()"
      :editMode="editMode" :productId="product.id" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import ProductQuestionForm from '@/components/forms/ProductQuestionForm.vue'

export default {
  components: { ProductQuestionForm },

  data() {
    return {
      popupModalShow: false,
      editMode: false,
      editItem: null,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    },
    questions(){
      return this.product.questions
    }
  },
  methods: {
    deleteItem(itemId){
      this.confirmDelete({
        text: "You are about to delete this question!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-questions/${itemId}/delete`)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.$store.commit("productList/DELETE_QUESTION", itemId)
            }
          })
        }
      });
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
}
</script>
