<template>
  <div>

    <attribute-list :productId="+$route.params.productId"
        @toggleModal="toggleModal($event)" />

    <div class="row">
      <div class="col-12 text-right">
        <b-button variant="warning" class="mr-2" @click.prevent="popupClone = true" type="button">Copy from</b-button>
        <b-button variant="primary" class=""
          @click.prevent="toggleModal(true)" type="button">
        <span class=""> Add Attribute</span></b-button>
      </div>
    </div>

    <b-modal v-model="popupClone" hide-footer centered
      title="Select product to copy from"
      title-class="font-18" @hide="initializeAll()">
      <div>
        <select v-model="cloneFrom"
          class="form-control mb-3" placeholder="--select product-">
          <option selected :value="null" >--select product--</option>
          <option v-for="(dPro, iIndex) in products" :key="iIndex"
          :value="dPro.id" >{{dPro.name}}</option>
        </select>
        <b-button variant="primary" @click.prevent="cloneNow()" type="button">Clone</b-button>
      </div>
    </b-modal>
  </div>

</template>

<script>
import AttributeList from "@/components/AttributeList.vue"

export default {
    components: {
        AttributeList,
    },
    data(){
        return{
            popupClone: false,
            cloneFrom: null
        }
    },
    computed: {
        products(){
          return this.$store.state.productList
              .products.map((p) => {return {id: p.id, name: p.name}})
        },
    },
    methods:{
        toggleModal(val = false){
            val ? this.$bvModal.show("attribute-List-modal")
            : this.$bvModal.hide("attribute-List-modal")
        },
        cloneNow(){
            if(!this.cloneFrom){this.alertError("Select product"); return;}
            let data = {from: this.cloneFrom, to: this.$route.params.productId}
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.post('/attributes/clone', data)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    let payload = {model: "PRODUCT" }
                    payload.product_id = this.$route.params.productId
                    this.$store.dispatch("attributeList/fetchAttributes", payload)
                    this.initializeAll()
                }
            })
        },
        initializeAll(){
            this.popupClone = false;
            this.cloneFrom = null
        },
    }
}
</script>

<style>

</style>