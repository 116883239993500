<template>
    <form class="">
        <div class="form-group mb-3">
          <label for="addonName">
              Name
              <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}"
          v-model="data.name" id="addonName" name="name"
          class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="Name" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="form-group mb-3">
          <label for="addonCharge">
            Charge <span class="text-danger">*</span>
          </label>
          <input type="number" v-validate="{required: true}"
          v-model="data.charge" id="addonCharge" name="charge"
          class="form-control" :class="{ 'is-invalid': errors.has('charge') }" placeholder="Enter charge" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('charge')">{{ errors.first('charge') }}</span>
        </div>
        <div class="form-group mb-3">
          <label for="addonCharge">
            Duration <span class="text-danger">*</span>
          </label>
          <select v-model="data.duration" v-validate="{required: true}"
            class="form-control" placeholder="--select day--" name="duration">
            <option selected value="" >--select day--</option>
            <option v-for="(dTime, dIndex) in deliveryDays" :key="dIndex"
            :value="dTime.value" >{{dTime.text}}</option>
          </select>
          <span class="text-danger invalid-feedback"  v-show="errors.has('duration')">{{ errors.first('duration') }}</span>
        </div>
        <div class="form-group mt-3">
          <label class="" for="addonDesc">Description</label>
          <div class="">
              <textarea id="addonDesc" v-validate="'max:100'"
              v-model="data.description" class="form-control"
              rows="2" placeholder="Write Description" name="description"></textarea>
              <span class="text-danger invalid-feedback"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
          </div>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? update() : create()" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
    </form>
</template>

<script>
export default {
    props:{
        productId:{
            type: Number,
            default: null
        },
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                charge: "",
                duration: "",
                description: "",
                product_id: this.productId
            },
        }
    },
    computed:{
        deliveryDays(){
            let days = [{value: 24, text: "1 day"}];
            for (let step = 2; step < 101; step++) {
                days.push({value: step*24, text: `${step} days`});
            }
            return days;
        }
    },
    methods:{
        create() {
            this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post("/addons/create", formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/ADD_ADDON", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
           this.$validator.validateAll().then(result => {
                if (!result) {this.alertError("Form not valid"); return }
                const formData = new FormData()
                this.$store.dispatch("changeLoaderValue", true)
                formData.append('data', JSON.stringify(this.data))
                this.$http.post(`/addons/${this.editItem.id}/update`,formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("productList/UPDATE_ADDON", response.data.data)
                        this.resetForm()
                    }
                })
            
            })
        },
        resetForm(){
            this.$validator.reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$options.filters
                        .patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>

<style>

</style>