<template>
    <div>
        <product-addons />
        <hr>
        <product-questions />
    </div>
</template>
<script>
import ProductAddons from "./ProductAddons.vue"
import ProductQuestions from "./ProductQuestions.vue"

export default {
    components:{
        ProductAddons,
        ProductQuestions,
    }
}
</script>
